@import url('./databoard.css');
@import url('./uploadmodal.css');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700&display=swap');
:root {
  font-size: 62.5%;
  --dialog-bg: #fdf3f3;
  --plain-white: #ffffff;
  --filter-button-color: #ca79c6;
  --databoard-page-color-fill: #f5f6fa;
  --sheets-dialog-text-color: #272727;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.dialog__overlay {
  height: 100vh;
  width: 100vw;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .dialog__input {
} */
.dialog__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.dialog__input {
  /* border: none;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.1rem;
  background: var(--plain-white);
  box-shadow: 0 0.4rem 1.5rem rgba(185, 178, 226, 0.64);
  border-radius: 0.8rem;
  width: 100%;
  height: 4.4rem;
  color: var(--dropdown-color);
  margin-right: 1.5rem; */
  font-weight: 400;
  font-size: 1.6rem;
  padding: 0.5rem 2rem;
  line-height: 3.6rem;
  width: 100%;
  /* identical to box height, or 225% */

  letter-spacing: -0.05em;
  border: 1px solid #c9c9c9;
  border-radius: 10px;

  color: #ababab;
}
.dialog__input::placeholder {
  color: black;
}
.dialog__inputslect {
  border: none;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.1rem;
  background: var(--plain-white);
  box-shadow: 0 0.4rem 1.5rem rgba(185, 178, 226, 0.64);
  border-radius: 0.8rem;
  width: 100%;
  height: 4.4rem;
  color: var(--dropdown-color);
  margin-right: 1.5rem;
  padding: 1rem;
}
.dialog__inputdate {
  border: none;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.1rem;
  background: var(--plain-white);
  box-shadow: 0 0.4rem 1.5rem rgba(185, 178, 226, 0.64);
  border-radius: 0.8rem;
  width: 100%;
  height: 4.4rem;
  color: var(--dropdown-color);
  margin-right: 1.5rem;
  padding: 1rem;
}
.dialog__formerr {
  text-align: end;
  color: red;
  font-size: 1.6rem;
}
.dialog__inputdate {
  font-size: 1.6rem;
  width: 100%;
}
.dialog {
  border-radius: 2rem;
  background-color: var(--plain-white);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
.dialog__header {
  text-align: center;
}
.dialog__btn {
  padding: 1rem 8rem;
  background-color: var(--tinge-blue);
  border: none;
  color: var(--plain-white);
  border-radius: 0.5rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-align: center;
  letter-spacing: -0.05em;
}
.dialog__inputlabel {
  font-size: 1.6rem;
}
.dialog__input::placeholder {
  color: black;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.dialog__btn--hide {
  background-color: var(--plain-white);
  color: var(--tinge-blue);
  box-shadow: 0 0 0 0.1rem var(--tinge-blue);
}
.sheetsdialog {
  padding: 4.5rem 6rem;
}
.sheetsdialog__header {
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 3.6rem;
  text-align: left;
  letter-spacing: -0.05em;
  color: var(--sheets-dialog-text-color);
  margin-bottom: 2rem;
}
.sheetsdialog__input {
  margin-bottom: 2rem;
}
.sheetsdialog__copy {
  margin-bottom: 2rem;
}
.sheetsdialog__instructions {
  margin-bottom: 2rem;
}
.sheets_instructionlist {
  margin-top: 0.5rem;
}
.wd-def {
  width: 81vw;
}
.error {
  font-size: 2rem;
  color: red;
  margin-top: 1.7rem;
}
