:root {
  font-size: 62.5%;
  --side-icon-hover: #ffffff;
  --plain-white: #ffffff;
  --side-icon-shadow: rgba(216, 210, 252, 0.64);
  --home-button: rgba(216, 210, 252, 0.64);
  --sidenav-background: #e5e5e5;
  --topbar-logos: rgba(216, 210, 252, 0.64);
  --parameter-value: rgba(123, 123, 123, 1);
  --scope-1-fill: #cfbef0;
  --scope-2-fill: #bbf1e3;
  --scope-3-fill: #ffefcd;
  --scope-4-fill: #ffd3d3;
  --graph-header-text: rgba(39, 30, 74, 1);
  --graoh-bg: rgba(255, 255, 255, 1);
  --area-graph-bg: rgba(255, 255, 255, 0.5);
  --area-graph-shadow1: rgba(50, 50, 71, 0.01);
  --area-graph-shadow2: rgba(50, 50, 71, 0.06);
  --web-plat-green-color: #79cfa6;
  --web-plat-red-color: #ff706f;
  --web-plat-bg: #e5e5e5;
  --databoard-tableheader: #271e4a;
  --dropdown-color: #271e4a;
  --filter-button-color: #ca79c6;
  --filter-label-color: rgba(39, 30, 74, 0.8);
  --data-board-bg: rgba(224, 233, 244, 1);
  --data-board-table-cont: rgba(224, 233, 244, 1);
  --checkbox-pink: #ca79c6;
  --checkbox-pink-shadow: rgba(185, 178, 226, 0.64);
  --action-btn-shadow: rgba(185, 178, 226, 0.64);
  --table-header-bg: #edf4fc;
  --table-row-bottom-border: #e0e9f4;
  --table-bottom-message: rgba(39, 30, 74, 0.8);
  --tinge-blue: #4d7cfe;
  --databoard-page-color-fill: #f5f6fa;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.datamain {
  background-color: var(--databoard-page-color-fill);
  display: grid;
  grid-template-rows: 6rem auto;
}
.databoardheader {
  font-weight: 600;
  font-size: 4.8rem;
  line-height: 4.8rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
/* 

*/
.databoardmaincontent {
  display: flex;
  flex-direction: column;
  padding: 0 5rem;
}
.databoardheader {
  font-weight: 600;
  font-size: 4.8rem;
  line-height: 4.8rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.databoardtabletop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 3rem;
}
.databoardtabletop__header {
  color: var(--tinge-blue);
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 2.4rem;
}
.databoardtabletop__norows {
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.4rem;
  color: #c9c9c9;
}
.databoardtable__theadtr {
  padding: 1rem 3rem;
  background-color: var(--databoard-page-color-fill);
}
.databoardtabletop__select {
  border: none;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.1rem;
  background: var(--plain-white);
  box-shadow: 0 0.4rem 1.5rem rgba(185, 178, 226, 0.64);
  border-radius: 0.8rem;
  width: 26.5rem;
  height: 4.4rem;
  color: var(--dropdown-color);
  margin-right: 1.5rem;
  padding: 1rem 1.5rem;
}
.databoardtabletop__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: var(--tinge-blue);
  padding: 1rem;
  border-radius: 1rem;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #ffffff;
}
.databoardtabletop__movetoviz {
  display: flex;
  background-color: var(--tinge-blue);
  padding: 1rem;
  border-radius: 1rem;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: var(--plain-white);
}
.databoardtabletop__movetoviz img {
  padding-right: 1rem;
}
.databoardtabletop__controls {
  display: flex;
  justify-content: center;
}
.databoardtabletop__controlname {
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: var(--filter-label-color);
  align-self: center;
  margin-right: 1.2rem;
}
.databoardtabletop__btnlogo {
  height: 1.8rem;
  width: 1.8rem;
}
.databoardtable__tabletr {
  height: 58px;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.1rem;
  padding: 1rem 3rem;
  text-align: center;
  background-color: var(--plain-white);
  box-shadow: 0 0 0 0.1rem var(--table-row-bottom-border);
}
.databoardtable__tabletr:first-child {
  box-shadow: none;
}
.databoardtablecont {
  background-color: var(--plain-white);
  /* width: 115.2rem; */
  min-height: 79.8rem;
  border-radius: 4.7rem;
  padding-top: 3rem;
  background: #ffffff;
  border-radius: 2rem;
}
.databoardtable__tablehead {
  font-weight: 400;
  /* font-size: 0.875rem; */
  line-height: 1.5rem;
  text-transform: uppercase;
  color: #7b7b7b;
  padding: 3rem;
  background-color: rgba(77, 124, 254, 0.03);
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  box-shadow: 0 0 0 0.1rem var(--table-row-bottom-border);
}
.databoardtable__theadth:last-child {
  margin-right: 1rem;
  padding: 2rem 0;
}
.databoardtable {
  margin-top: 1.4rem;
}
.databoardtable__table {
  width: 100%;
  border-spacing: 0;
  overflow: hidden;
}
.databoardtable__tablecheck input {
  position: relative;
  outline: 0.1rem solid black;
}
.databoardtable__tablecheck input::before {
  position: absolute;
  content: '';
  outline: 0.1rem solid black;
  height: 1.6rem;
  width: 1.6rem;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border-radius: 0.4rem;
}
.databoardtable__tablecheck input:checked::before {
  background-color: var(--checkbox-pink);
  border: none;
  border-radius: 0.4rem;
  box-shadow: 0 0.4rem 1.5rem var(--checkbox-pink-shadow);
  outline: none;
  cursor: pointer;
}
.databoardtable__tablecheck input:checked::after {
  position: absolute;
  transform: translate(25%, -5%);
  content: url('../../../assets/databoard/check.svg');
  outline: none;
  cursor: pointer;
}
.databoardtable__csource {
  display: flex;
  justify-content: center;
}
.databoardtable__csourceimg {
  max-width: 100%;
  max-height: 100%;
}
.databoardtable__tablebtn {
  width: 3rem;
  height: 3rem;
  background: var(--plain-white);
  box-shadow: 0 0.4rem 1.5rem var(--action-btn-shadow);
  border-radius: 0.8rem;
  border: none;
}
.databoardtable__tableactions {
  display: flex;
  justify-content: center;
}
.databoardtable__tablebtn:first-child {
  margin-right: 2rem;
}
.downarrow {
  font-size: 1.6rem;
}
.databoardmainbottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 4rem;
}
.databoardmainbottom__rowmessage {
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--table-bottom-message);
  margin-left: 3.3rem;
}
.databoardmainbottom__addbtn {
  padding: 1rem 2rem;
  background-color: var(--filter-button-color);
  border: none;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: var(--plain-white);
  margin-right: 3.6rem;
}
.blank__letters {
  visibility: hidden;
}
.databoardupoptions {
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}
/* .databoardupoption {
} */
.databoardupoption_btn {
  border-radius: 0.5rem;
  border: 0.05rem solid var(--tinge-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  padding: 1rem;
  background-color: var(--plain-white);
}
.databoardupoption_btnlogo {
  /* width: 4.1rem; */
  width: 2.4rem;
  height: 2.4rem;
}
.databoardupoption_btntxt {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.7rem;
  color: var(--tinge-blue);
}
.sheets_instructionlist {
  list-style-type: none;
}
.sdhufuhf {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 2.4rem;
}
.dashgraphbarcont,
.dashgraphlinecont {
  width: 100%;
}
input,
select {
  display: block;
}
.data-cargo {
  display: flex;
  height: 50px;
  justify-content: space-between;
}
.data-com {
  display: flex;
  height: 50px;
  justify-content: space-between;
}
.data-e {
  display: flex;
  height: 50px;
  justify-content: space-between;
}
.data-build {
  display: flex;
  height: 50px;
  justify-content: space-between;
}
.data-del {
  display: flex;
  height: 50px;
  justify-content: space-between;
}
.data-fuel {
  display: flex;
  height: 50px;
  justify-content: space-between;
}
.data-pro {
  display: flex;
  height: 50px;
  justify-content: space-between;
}
